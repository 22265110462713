import React from "react";
import theme from "theme";
import { Theme, Text, Link, Strong, List, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact"} />
		<Helmet>
			<title>
				IQResearches | Contact Us
			</title>
			<meta name={"description"} content={"Cognitive IQ Test – a carefully crafted journey to assess your intellectual abilities and analytical skills. "} />
			<meta property={"og:title"} content={"Brain Rank IQ Test"} />
			<meta property={"og:description"} content={"Cognitive IQ Test – a carefully crafted journey to assess your intellectual abilities and analytical skills. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/proveit.webp?v=2024-03-04T16:16:07.691Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
		</Helmet>
		<Components.NavBar>
			<Override slot="quarklycommunityKitMenu" />
			<Override slot="quarklycommunityKitMenuOverride1" />
		</Components.NavBar>
		<Section background="--color-darkL1" padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
				border-color="--color-light"
				color="--light"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					as="h1"
				>
					Contacts{"  "}
				</Text>
				<Text margin="0px 0px 48px 0px" font="--base">
					At iqresearches.com, we strive to ensure a seamless and enjoyable experience with our Brain Rank Test. Should you require any assistance or have specific inquiries, we're here to help. For any support or questions, please feel free to email us at hello@iqresearches.com.
					<br />
					<br />
					We appreciate your visit to our site and hope you find your experience both stimulating and insightful. Your feedback is always welcome.
				</Text>
				<Link
					href="mailto:blank?hello@company.com"
					text-align="center"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
					color="--lightBlue"
					hover-color="--secondary"
				>
					hello@iqresearches.com
				</Link>
				<Text font="--headline3" text-align="center">
					Wavedropper LTD
				</Text>
				<Text margin="0px 0px 48px 0px" font="--base" text-align="center">
					Company number 15491549
				</Text>
				<Text margin="0px 0px 48px 0px" font="--headline3" text-align="center">
					71-75 Shelton Street, Covent Garden,
					<br />
					London, United Kingdom,
					<br />
					WC2H 9JQ
				</Text>
				<Text margin="0px 0px 48px 0px" font="--base">
					For our customers in the EU, Norway, Iceland, and Liechtenstein, please note:
				</Text>
				<List margin="0px 0px 0px 0px" padding="0px 0px 0px 20px" list-style-type="disc" as="ul">
					<Text margin="0px 0px 0px 0px">
						You can directly contact us via email at{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							hello@iqresearches.com
						</Strong>
						.{" "}
					</Text>
					<Text margin="16px 0px 0px 0px">
						For more information on online dispute resolution, visit the EU’s ODR platform at{" "}
						<Link href="https://ec.europa.eu/consumers/odr" color="--lightBlue" hover-color="--secondary" target="_blank">
							ec.europa.eu/consumers/odr
						</Link>
						.
					</Text>
				</List>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b3caaa009abe001f026b4e"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<link href={"https://iqresearches.com/contact/"} rel={"canonical"} place={"endOfHead"} rawKey={"661e644f54763c4eb4c88aac"} />
		</RawHtml>
	</Theme>;
});